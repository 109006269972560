import { createSlice } from '@reduxjs/toolkit';

export const backdropSlice = createSlice({
  name: 'backdrop',
  initialState: {
    content: '',
    open: false,
  },
  reducers: {
    bdopen: (state, action) => {
      state.content = action.payload;
      state.open = true;
    },
    bdclose: (state) => {
      state.open = false;
    },
  },
});

export const { bdopen, bdclose } = backdropSlice.actions;

export default backdropSlice.reducer;
