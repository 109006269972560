import { configureStore } from '@reduxjs/toolkit';
import backdropReducer from './backdropSlice';
import switchHotelsReducer from './switchHotelsSlice';

export default configureStore({
  reducer: {
    backdrop: backdropReducer,
    switchHotels: switchHotelsReducer,
  },
});
