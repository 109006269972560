import axios from 'axios';
import { Cookies } from 'react-cookie';
import toast from 'react-hot-toast';

const cookies = new Cookies();

const URLS = {
  login: '/api/auth/login/',
  user: '/api/user/',
  account: '/api/accounts/account/',
  password: '/api/accounts/password/update/',
  switchHotel: '/api/accounts/hotel/switch/',
  refreshToke: '/api/auth/refresh/',
  command: '/api/core/command/',
  pushBarRate: '/api/core/pushbarrate/',
  segment: '/api/core/segment/',
  channel: '/api/core/segmentfilter/',
  ignore: '/api/params/ignore/',
  dailyprice: '/api/params/dailyprice/',
  pricecsv: '/api/params/pricecsv/',
  batch: '/api/params/batch/',
  staticvar: '/api/params/staticvar/',
  importsegment: '/api/params/importsegment/',
  hotel: '/api/hotels/hotel/',
  hotelmisc: '/api/hotels/hotelmisc/',
  roomtype: '/api/hotels/roomtype/',
  pmsroomtype: '/api/hotels/pmsroomtype/',
  record: '/api/records/',
  dashboard: '/api/dashboard/',
  dashboard2: '/api/dashboard2/',
  version: '/api/frontend/versions/',
  calendarDays: '/api/calendar/days/',
  calendarDay: '/api/calendar/day/',
  tips: '/api/tips/',
  nopricealerts: '/api/nopricealerts/',
  recordtypes: '/api/recordtypes/',
};

const request = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
});

// api整理在這個section
const login = (username, password, token) => request.post(URLS.login, {
  name: username,
  password,
  token,
});

const switchHotel = (hotelId) => request.get(`${URLS.switchHotel}${hotelId}/`, {
  headers: {
    Authorization: `Bearer ${cookies.get('access', { path: '/' })}`,
  },
});

const refreshToken = () => request.post(URLS.refreshToke, {
  refresh: cookies.get('refresh', { path: '/' }),
});

const retrieve = (url, pk) => request.get(`${URLS[url]}${pk}/`, {
  headers: {
    Authorization: `Bearer ${cookies.get('access', { path: '/' })}`,
  },
});

const list = (url, data) => request.get(URLS[url], {
  headers: {
    Authorization: `Bearer ${cookies.get('access', { path: '/' })}`,
  },
  params: data,
});

const create = (url, data) => request.post(URLS[url], data, {
  headers: {
    Authorization: `Bearer ${cookies.get('access', { path: '/' })}`,
  },
});

const destroy = (url, pk) => request.delete(`${URLS[url]}${pk}/`, {
  headers: {
    Authorization: `Bearer ${cookies.get('access', { path: '/' })}`,
  },
});

const update = (url, pk, data) => request.put(`${URLS[url]}${pk}/`, data, {
  headers: {
    Authorization: `Bearer ${cookies.get('access', { path: '/' })}`,
  },
});

const download = (url, data) => request.get(URLS[url], {
  responseType: 'blob',
  headers: {
    Authorization: `Bearer ${cookies.get('access', { path: '/' })}`,
  },
  params: data,
});

const upload = (url, formData) => request.post(URLS[url], formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${cookies.get('access', { path: '/' })}`,
  },
});

// 統一處理error response
request.interceptors.response.use((response) => response, async (error) => {
  if (error.response) {
    if (![URLS.refreshToke, URLS.login].includes(error.config.url)) {
      // server responded status code falls out of the range of 2xx
      switch (error.response.status) {
        case 401: {
          // 原始 request 資訊
          const originalRequest = error.config;
          // 依據 refresh_token 刷新 access_token 並重發 request
          const refresh = cookies.get('refresh', { path: '/' });
          if (refresh) {
            const req = await request
              .post(URLS.refreshToke, {
                refresh: cookies.get('refresh', { path: '/' }),
              }) // refresh_toke is attached in cookie
              .then((response) => {
                // [更新 access_token 成功]

                // 刷新 storage (其他呼叫 api 的地方都會從此處取得新 access_token)
                cookies.set('access', response.data.access, { secure: true, path: '/' });
                // 刷新原始 request 的 access_token
                originalRequest.headers.Authorization = `Bearer ${response.data.access}`;

                // 重送 request (with new access_token)
                return axios(originalRequest);
              })
              .catch(() => {
                // [更新 access_token 失敗] ( e.g. refresh_token 過期無效)
                cookies.remove('user', { path: '/' });
                cookies.remove('access', { path: '/' });
                cookies.remove('refresh', { path: '/' });
                window.location.href = '/signin';
                return Promise.reject(error);
              });
            return req;
          }
          cookies.remove('user', { path: '/' });
          cookies.remove('access', { path: '/' });
          window.location.href = '/signin';
          break;
        }
        default:
          toast.error(`${error.response.status} ${error.response.data.message}`);
          break;
      }
    }
  } else {
    toast.error('網路連線異常。', {
      duration: 6000,
    });
  }

  return Promise.reject(error);
});

export default {
  request,
  login,
  switchHotel,
  refreshToken,
  retrieve,
  list,
  create,
  destroy,
  update,
  download,
  upload,
};
