/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiutil from '../apiutil';

export const getSwitchHotels = createAsyncThunk(
  'getSwitchHotels',
  async () => {
    const response = await apiutil.list('switchHotel');
    return response.data;
  },
);

export const switchHotelsSlice = createSlice({
  name: 'switchHotels',
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSwitchHotels.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const { bdopen, bdclose } = switchHotelsSlice.actions;

export default switchHotelsSlice.reducer;
