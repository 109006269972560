import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useCookies, CookiesProvider } from 'react-cookie';
import appStore from './redux/appStore';
import SignIn from './SignIn';
import apiutil from './apiutil';

const RmsContent = React.lazy(() => import('./RmsContent'));

const mdTheme = createTheme({
  palette: {
    primary: {
      main: '#0b2039',
    },
    secondary: {
      main: '#f50057',
    },
    warning: {
      main: '#f50057',
    },
    shalom: {
      main: '#46b0de',
      contrastText: '#ffffff',
    },
    white: {
      main: '#ffffff',
    },
  },
});

function Loader() {
  return (
    <Box sx={{
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      bgcolor: mdTheme.palette.grey[100],
    }}
    >
      <CircularProgress />
      <Box sx={{ position: 'absolute', bottom: '20px' }}>
        <img src="/static/img/logo.png" alt="Shalom inc." />
      </Box>
    </Box>
  );
}

function RmsApp() {
  const [cookies, setCookie, removeCookie] = useCookies(['user', 'access', 'refresh']);
  const refreshUser = async () => {
    await apiutil.refreshToken().then((res) => {
      setCookie('access', res.data.access, { secure: true, path: '/' });
    }).catch((error) => {
      if (error.response) {
        removeCookie('refresh', { path: '/' });
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
    });
  };
  if (!cookies.access && cookies.refresh) {
    refreshUser();
  }
  return (
    <ThemeProvider theme={mdTheme}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/app/home" />
        </Route>
        <Route exact path="/signin">
          {cookies.user && cookies.access ? <Redirect to="/app/home" /> : <SignIn />}
        </Route>
        <Route path="/app">
          {((!cookies.refresh && !cookies.access) || !cookies.user) && <Redirect to="/signin" />}
          <Suspense fallback={<Loader />}>
            <RmsContent />
          </Suspense>
        </Route>
      </Switch>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const RmsAppContainer = connect(mapStateToProps)(RmsApp);

export default SignIn;

ReactDOM.render(
  <CookiesProvider>
    <Provider store={appStore}>
      <BrowserRouter>
        <RmsAppContainer />
      </BrowserRouter>
    </Provider>
  </CookiesProvider>,
  document.getElementById('root'),
);
