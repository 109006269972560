/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import * as dateFns from 'date-fns';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ReCAPTCHA from 'react-google-recaptcha';
import { useCookies } from 'react-cookie';
import apiutil from './apiutil';

function Copyright(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright 2021 © '}
      <Link color="inherit" href="https://www.shalom.com.tw/">
        Shalom Inc.
      </Link>
      <br />
      All rights reserved.
    </Typography>
  );
}

function SignIn() {
  const [nameField, setNameField] = React.useState({
    error: false,
    helperText: '',
  });
  const [passwordField, setPasswordField] = React.useState({
    error: false,
    helperText: '',
  });
  const [remember, setRemember] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState({ show: false, msg: '' });
  const validate = (data) => {
    const name = data.get('name');
    const password = data.get('password');
    let success = true;
    if (name === '') {
      success = false;
      setNameField({
        error: true,
        helperText: '請填寫帳號',
      });
    } else {
      setNameField({
        error: false,
        helperText: '',
      });
    }

    if (password === '') {
      success = false;
      setPasswordField({
        error: true,
        helperText: '請填寫密碼',
      });
    } else {
      setPasswordField({
        error: false,
        helperText: '',
      });
    }
    return success;
  };
  const recaptchaRef = React.useRef();
  const [, setCookie, removeCookie] = useCookies(['user', 'access', 'refresh']);
  const handleRemember = (event) => {
    setRemember(event.target.checked);
  };

  const handleChange = () => {
    setErrMsg({ show: false, msg: '' });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    if (!validate(data)) {
      return;
    }
    const token = await recaptchaRef.current.executeAsync();
    if (token) {
      apiutil.login(data.get('name'), data.get('password'), token)
        .then((res2) => {
          setCookie('access', res2.data.access, { secure: true, path: '/' });
          if (remember) {
            const expireDate = dateFns.addWeeks(new Date(), 1);
            setCookie('user', JSON.stringify(res2.data.user), { secure: true, expires: expireDate, path: '/' });
            setCookie('refresh', res2.data.refresh, { secure: true, expires: expireDate, path: '/' });
            setCookie('expireDate', expireDate, { secure: true, expires: expireDate, path: '/' });
          } else {
            setCookie('user', JSON.stringify(res2.data.user), { secure: true, path: '/' });
            removeCookie('refresh');
          }
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 400:
                setErrMsg({ show: true, msg: '驗證失敗，請確認您輸入的資料是否正確。' });
                break;
              case 401:
                setErrMsg({ show: true, msg: '使用者名稱或密碼不正確。' });
                break;
              case 500:
                setErrMsg({ show: true, msg: '系統錯誤，請通知網站管理人員。' });
                break;
              default:
                break;
            }
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
        });
    }
    recaptchaRef.current.reset();
  };

  return (
    <Container component="main" sx={{ width: '360px' }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box component="img" src="/static/img/logo.png" />
        <Typography component="h1" variant="h6" sx={{ mt: 3, mb: 1 }}>
          歡迎回到旅安收益管理系統
        </Typography>
        <Collapse in={errMsg.show}>
          <Alert icon={false} severity="error" sx={{ width: '310px' }}>
            {errMsg.msg}
          </Alert>
        </Collapse>
        <Box component="form" onSubmit={(handleLogin)} noValidate sx={{ mt: 1 }}>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LcvhWYdAAAAAIX4ODsDbvP-pOnBS0zVYnSj-NCQ"
          />
          <TextField
            size="small"
            margin="normal"
            error={nameField.error}
            helperText={nameField.helperText}
            fullWidth
            id="name"
            label="帳號"
            name="name"
            autoComplete="name"
            onChange={handleChange}
            autoFocus
          />
          <TextField
            size="small"
            margin="normal"
            error={passwordField.error}
            helperText={passwordField.helperText}
            fullWidth
            name="password"
            label="密碼"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={remember}
                onChange={handleRemember}
                name="remember"
                value="remember"
                color="primary"
              />
            )}
            label="記住我"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            登入
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}

export default SignIn;
